.DepartureItem {
  display: flex;
  margin-bottom: 10px;

  color: white;

  .destination {
    flex-grow: 1;
    display: flex;

    .destination-icon {
      margin-right: 5px;
    }

    .destination-name {
      font-weight: bold;
    }
  }

  .messages {
    flex-grow: 1;
    text-align: center;
  }

  .timeleft {
    flex-grow: 1;
    text-align: right;
    font-weight: bold;
  }
}
